import React from "react";
import { SzButton, SzIcon, SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import "./collapse.scss";

interface ICollapseProps {
  title: string;
  description: string;
  archiveHandler: () => void;
  linkHandler: () => void;
  type: string | null;
  hideArchiveAction: boolean;
}
export default function Collapse(props: ICollapseProps) {
    const { title, description, archiveHandler, linkHandler, type, hideArchiveAction } = props;
    const { t } = useTranslation(); 
    return (
      <div className="sz-collapse mb-3 border-0 card">
        <div
          className={
            'sz-collapse__header sz-left-border position-relative row align-items-stretch no-gutters pl-5 py-1 cat-' +
            type
          }
        >
          <div className="col my-4 py-1">
            <div className="container">
              <div className="row">
                <div className="col">
                  <SzTypographie variant="body" weight="bold" className="mb-2">
                    {title}
                  </SzTypographie>
                  <SzTypographie
                    variant="caption"
                    weight="medium"
                    className="caption"
                  >
                    {description}
                  </SzTypographie>
                </div>
                <div className="col-4">
                  <SzButton
                    className="more"
                    variant="tertiary"
                    icon="arrow-right"
                    alignIcon="right"
                    onClick={linkHandler}
                  >
                    {t('NOTIFICATIONS_more')}
                  </SzButton>
                </div>
              </div>
            </div>
          </div>
          {hideArchiveAction && (
            <div className="px-5 py-4 my-auto ml-4 border-left">
              <SzIcon
                icon="close"
                variant="line"
                className="d-flex align-items-center cursor-pointer"
                onClick={archiveHandler}
              />
            </div>
          )}
        </div>
      </div>
    );
}
