declare global {
    interface Window {
      REACT_APP_API_HOST: any;
      REACT_APP_SUPPORT_HOST: any;
      REACT_APP_ANALYTICS_KEY: any;
      REACT_APP_REQUEST_MODULE_URL: any;
      REACT_APP_PORTAL_MODULE_URL: any;
      REACT_APP_INDICATOR_MODULE_URL: any;
      REACT_APP_GED_MODULE_URL: any;
      REACT_APP_REQ_MODULE_URL: any;
      REACT_APP_ACCOUNT_MANAGER_URL: any;
      REACT_APP_URBA_URL: any;
      REACT_APP_ITV_URL: any;
      REACT_APP_VIS_URL: any;
      REACT_APP_MATOMO_URL_BASE: string;
      REACT_APP_MATOMO_SET_SECURE_COOKIE: boolean;
      REACT_APP_MATOMO_DISABLED: boolean;
      REACT_APP_MATOMO_DIMENSION_CONTRACT_ID: number;
      REACT_APP_MATOMO_DIMENSION_SERVICE_ID: number;
      REACT_APP_MATOMO_SITE_ID: number;
    }
}
const url = new URL(window.location.href);

// force test env (use REACT_APP_API_TEST_HOST for api calls)
const testEnv = url.searchParams.get("test_env");
if (testEnv) {
    localStorage.setItem("test_env", testEnv);
}
const isTestEnv = localStorage.getItem("test_env");
let apiUrl = window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;
let supportUrl = window.REACT_APP_SUPPORT_HOST || process.env.REACT_APP_SUPPORT_HOST;
let googleAnalyticsId = window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY;
let accountManagerUrl = window.REACT_APP_ACCOUNT_MANAGER_URL || process.env.REACT_APP_ACCOUNT_MANAGER_URL;

// Modules
let RequestModuleUrl = window.REACT_APP_REQUEST_MODULE_URL || process.env.REACT_APP_REQUEST_MODULE_URL;
let PortalModuleUrl = window.REACT_APP_PORTAL_MODULE_URL || process.env.REACT_APP_PORTAL_MODULE_URL;
let gedModuleUrl = window.REACT_APP_GED_MODULE_URL || process.env.REACT_APP_GED_MODULE_URL;
let reqModuleUrl = window.REACT_APP_REQ_MODULE_URL || process.env.REACT_APP_REQ_MODULE_URL;
let indicatorModuleUrl = window.REACT_APP_INDICATOR_MODULE_URL || process.env.REACT_APP_INDICATOR_MODULE_URL;
let urbaModuleUrl = window.REACT_APP_URBA_URL || process.env.REACT_APP_URBA_URL;
let itvModuleUrl = window.REACT_APP_ITV_URL || process.env.REACT_APP_ITV_URL;
let visModuleUrl = window.REACT_APP_VIS_URL || process.env.REACT_APP_VIS_URL;

let matomoUrlBase = window.REACT_APP_MATOMO_URL_BASE || process.env.REACT_APP_MATOMO_URL_BASE || '';
let matomoSiteId = (window.REACT_APP_MATOMO_SITE_ID || process.env.REACT_APP_MATOMO_SITE_ID) as number;
let matomoSetSecureCookie = (window.REACT_APP_MATOMO_SET_SECURE_COOKIE || process.env.REACT_APP_MATOMO_SET_SECURE_COOKIE) === "true";
let matomoDisabled = (window.REACT_APP_MATOMO_DISABLED || process.env.REACT_APP_MATOMO_DISABLED) === "true";
let matomoDimensionContractId = window.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID || process.env.REACT_APP_MATOMO_DIMENSION_CONTRACT_ID;
let matomoDimensionServiceId = window.REACT_APP_MATOMO_DIMENSION_SERVICE_ID || process.env.REACT_APP_MATOMO_DIMENSION_SERVICE_ID;

if (process.env.REACT_APP_API_TEST_HOST && isTestEnv && 1 === parseInt(isTestEnv)) {
    apiUrl = process.env.REACT_APP_API_TEST_HOST;
}

export const config = {
  apiUrl: apiUrl,
  accountManagerUrl: accountManagerUrl,
  supportUrl: supportUrl,
  googleAnalyticsId: googleAnalyticsId,
  RequestModuleUrl,
  PortalModuleUrl,
  gedModuleUrl,
  reqModuleUrl,
  indicatorModuleUrl,
  urbaModuleUrl,
  itvModuleUrl,
  visModuleUrl,
  matomoUrlBase: matomoUrlBase,
  matomoSetSecureCookie: matomoSetSecureCookie,
  matomoSiteId: matomoSiteId,
  matomoDisabled: matomoDisabled,
  matomoDimensionContractId: matomoDimensionContractId,
  matomoDimensionServiceId: matomoDimensionServiceId
};
