import React from "react";
import { SzHeader } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import "./header.scss";
import { Link } from 'react-router-dom';

interface IHeadersProps {
    isError: boolean;
    handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    user: any;
}

export default function Header(Props: IHeadersProps) {
    const { handleOnClick } = Props;
    const { t } = useTranslation();

    const logoutLink = {
            content: t('menu_logout'),
            link: '/logout',
            icon: 'sz-icon-line logout-2'
        };

    const logoLink = (
        <a className="" href="#!">
            <img src="https://projets-suez.github.io/sds/v1/img/toulouse-metropole-logo.svg" alt="" height={40}/>
        </a>
    );

    const userMenu = (
        <Link to={logoutLink.link} className="d-flex align-items-center">
            <span className={`${logoutLink.icon} pr-2`}/>
            {logoutLink.content}
        </Link>
    );

    return (
        <SzHeader className="d-flex align-items-stretch">
            {
                !Props.isError && <div className="group menu text-secondary"
                                       onClick={handleOnClick}>
                    <span className="sz-icon-line navigation-menu"/>
                </div>
            }
            <div className="group">
                {logoLink}
            </div>
            <div className="group no-border title"/>
            <div className="group no-border">
                {userMenu}
            </div>
        </SzHeader>
    );
}
