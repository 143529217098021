import React from 'react';
import ModuleTitle from "../moduleTitle";
import { useTranslation } from "react-i18next";
import { SzTypographie } from '@suezenv/react-theme-components';
import { Modes } from '../../../constants';

const AlertModule = (props:any) => {
    const { t } = useTranslation();
    const { module, moduleTitle, idPortal, idEmail, portalChecked, emailChecked, handleToggleCheckbox} = props

    return (
        <>
        <div className="col pl-5">
            <div className="row">
                <div className="col">
                    <ModuleTitle module={moduleTitle} className="float-left mr-5"/>
                    <SzTypographie weight="bold">
                        {t('MODULES_' + moduleTitle)}
                    </SzTypographie>
                </div>
                <div className="col d-none d-lg-block">
                    <SzTypographie weight="medium" variant="caption"
                                className="caption text-right">
                        {t(('MODULES_%s_desc_short').replace('%s', moduleTitle))}
                    </SzTypographie>
                </div>
            </div>
        </div>
        <div className="col-3 col-lg-2">
            <div className="custom-control custom-switch switch-only">
                <input type="checkbox"
                    id={idPortal}
                    className="custom-control-input"
                    defaultChecked={portalChecked}
                    onClick={() => handleToggleCheckbox(module, Modes.portal, portalChecked)}/>
                <label className="custom-control-label" htmlFor={idPortal}/>
            </div>
        </div>
        <div className="col-3 col-lg-2">
            <div className="custom-control custom-switch switch-only">
                <input type="checkbox"
                    id={idEmail}
                    className="custom-control-input"
                    defaultChecked={emailChecked}
                    onClick={() => handleToggleCheckbox(module, Modes.email, emailChecked)}
                    />
                <label className="custom-control-label" htmlFor={idEmail}/>
            </div>
        </div>
        </>
    )
}

export default AlertModule;