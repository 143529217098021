import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon, SzSideMenu } from '@suezenv/react-theme-components';
import { isMobile } from 'react-device-detect';
import { config } from "../../config";
import Header from "../elements/header";
import ModuleTitle from "../elements/moduleTitle";
import Loading from "../elements/loading";
import LoadingPage from "../../pages/loadingPage";
import "./navigation.scss";
import NavModules from "../elements/navModules";
import FreshdeskComponent from "../freshdesk/freshdesk";


interface INavigationProps {
    children: ReactNode;
    isError: boolean;
    loading: Boolean;
    logged: Boolean;
    userRoles?: string[];
    user: any;
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
    const [state, setState] = useState({ isNavOpen: false, showNavModules: false })
    const { children, user, userRoles } = props;
    const { t } = useTranslation();

    const closeMenuHandler = () => {
        setState({ ...state, showNavModules: false })
    }

    const toggleMenuHandler = () => {
        setState({
            ...state,
            showNavModules: isMobile ? true : !state.showNavModules,
            isNavOpen: !isMobile && state.isNavOpen
        })
        console.log(isMobile);
    }

    const accountManagerMenuItem = () => {
        if (userRoles && userRoles.indexOf("ROLE_ADM-ATLAS-ADMINS") !== -1) {
            return <SzSideMenu.Item
                  key={0}
                  active={false}
                  onClick={() => {window.open(config.accountManagerUrl, '_blank');}}
                >
                    <SzIcon icon="single-neutral-actions-setting" variant="line" />
                    <span className='sz-aside__item--title'>{t('ACCOUNT_MANAGER_title')}</span>
                </SzSideMenu.Item>
        }
        else {
            return <></>;
        }
    }

    const menu = [
        {
            icon: <SzIcon icon="layout-corners-dashboard-1" variant="line" />,
            title: t('modules'),
            active: true,
            handleClick: toggleMenuHandler
        }
    ];
    const SzSideMenuClassName = 'flex-shrink-0 d-flex align-items-end' + (!state.isNavOpen ? ' sz-aside-exit-done' : '');

    return (
        props.logged || props.isError ? (<>
            <header>
                <Header isError={props.isError}
                    handleOnClick={() => {
                        setState({ ...state, isNavOpen: !state.isNavOpen })
                    }}
                    user={user} />
            </header>
            <div className="d-flex flex-row flex-fill main-wrapper">
                {!props.isError &&
                    <SzSideMenu className={SzSideMenuClassName} isOpen={state.isNavOpen}>
                        {accountManagerMenuItem()}
                        <li className="sz-aside__item d-flex align-items-center">
                            <FreshdeskComponent user={user} />
                            <span className="sz-aside__item--title">{t('SUPPORT_title')}</span>
                        </li>
                        {menu.map(({ title, active, icon, handleClick }, index) => (
                            <SzSideMenu.Item
                                key={index}
                                active={active}
                                onClick={handleClick}
                            >
                                {icon && icon}
                                <span className='sz-aside__item--title'>{title}</span>
                            </SzSideMenu.Item>
                        ))}
                        <li>
                            <ModuleTitle />
                        </li>
                    </SzSideMenu>
                }
                <main className='flex-grow-1 d-flex flex-column'>
                    {props.loading && (<Loading />)}
                    <div className="flex-fill position-relative no-scroll h-100">
                        {state.showNavModules &&
                            <NavModules closeMenuHandler={closeMenuHandler}
                                user={user}
                            />
                        }
                        {children}
                    </div>
                </main>
            </div>
        </>) : (
                <LoadingPage />
            )
    );
};

const mapStateToProps = (state: any) => ({
    userRoles: state.user.info.roles,
});

export default connect(mapStateToProps, null)(Navigation);
