import React from "react";
import "./navModules.scss";
import { SzBox, SzIcon, SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from "react-i18next";
import { Modules, ModalTabs } from "../../../constants";
import { ReactComponent as DocumentsIcon } from '../../../assets/icon/modules/documents.svg';
import { ReactComponent as IndicatorsIcon } from '../../../assets/icon/modules/indicators.svg';
import { ReactComponent as PortalIcon } from '../../../assets/icon/modules/portal.svg';
import { ReactComponent as RequesterIcon } from '../../../assets/icon/modules/requester.svg';
import { ReactComponent as RequestsIcon } from '../../../assets/icon/modules/requests.svg';
import { ReactComponent as UrbanismIcon } from '../../../assets/icon/modules/urbanism.svg';
import { ReactComponent as ItvIcon } from '../../../assets/icon/modules/itv.svg';
import { ReactComponent as VisIcon } from '../../../assets/icon/modules/vis.svg';
import * as action from '../../../actions';
import {config} from '../../../config';
import { connect } from "react-redux";

const navModules = function NavModules(props: any) {
    const { t } = useTranslation();
    const {organisation, firstName, lastName } = props.user

    const iconComponents = {
        documents: { component: DocumentsIcon, serviceRequired: Modules.documents, href: config.gedModuleUrl },
        indicators: { component: IndicatorsIcon, serviceRequired: Modules.indicators, href: config.indicatorModuleUrl },
        portal: { component: PortalIcon, serviceRequired: Modules.portal, href: config.PortalModuleUrl },
        requester: { component: RequesterIcon, serviceRequired: Modules.requester, href: config.reqModuleUrl },
        requests: { component: RequestsIcon, serviceRequired: Modules.requests, href: config.RequestModuleUrl },
        urbanism: { component: UrbanismIcon, serviceRequired: Modules.urbanism, href: config.urbaModuleUrl },
        itv: {component: ItvIcon, serviceRequired: Modules.itv, href: config.itvModuleUrl},
        vis: {component: VisIcon, serviceRequired: Modules.vis, href: config.visModuleUrl},
    };

    const { closeMenuHandler, user } = props;

    const clickToProfile = () => {
        // Close menu & backdrop, dispatch show Profile tab action
        closeMenuHandler()
        props.showModal(ModalTabs.PROFILE)
    }

    return (
        <>
            <div className="nav-modules-overlay" onClick={closeMenuHandler}/>
            <nav className="container-fluid nav-modules">
                <div className="row">
                    <header className="col py-3">
                        <SzTypographie variant="h1" weight="regular">{t('portal_name')}</SzTypographie>
                        <SzTypographie variant="caption" weight="medium">{t('portal_slogan')}</SzTypographie>
                    </header>
                </div>
                <div className="row user-block">
                    <div className="col py-3">
                        <div className="row">
                            <div className="col-8">
                                <SzTypographie variant="caption" weight="medium">{t('identity')}</SzTypographie>
                                <SzTypographie weight="medium" className="mb-0">{firstName} {lastName}</SzTypographie>
                            </div>
                            <div className="col-4 text-right">
                                <SzIcon onClick={clickToProfile}
                                        icon="single-neutral-actions-information"
                                        variant="line"
                                        className="icon-big"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row user-block">
                    <div className="col py-3">
                        <SzTypographie variant="caption" weight="medium">{t('organization')}</SzTypographie>
                        <SzTypographie weight="medium" className="mb-0">{organisation}</SzTypographie>
                    </div>
                </div>
                <div className="row py-4 modules-block">
                    <div className="col">
                        <ul className="list-unstyled">
                            {Object
                                .entries(iconComponents)
                                .filter((value) => {
                                    const serviceRequired = 'ROLE_' + value[1].serviceRequired.toUpperCase();
                                    const userRoles = user.roles;
                                    return userRoles.includes(serviceRequired);
                                })
                                .map(([key, value]) => {
                                    const IconComponent = value.component;

                                    return (
                                        <li className="list-inline-item mx-3 my-2" key={key}>
                                            <SzBox className="box-shadow py-4" >
                                                <a href={value.href} target="_blank" rel="noopener noreferrer">
                                                    <IconComponent width="64px" height="64px" />
                                                </a>
                                            </SzBox>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col text-center">
                        <SzTypographie variant="button" weight="medium" className="d-inline-flex align-items-center">
                            {t('more_info')}
                        </SzTypographie>
                        <SzIcon
                            icon="information-circle"
                            variant="line"
                            className="icon ml-4"
                            onClick={() => props.showModal(ModalTabs.MODULES)}
                        />
                    </div>
                </div>
                <div className="row">
                    <footer className="col py-2 text-center">
                        <SzTypographie variant="caption" weight="medium">
                            {t('you_are_into_your_360')}
                        </SzTypographie>
                    </footer>
                </div>
            </nav>
        </>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user.info
});

const mapDispatchToProps = (dispatch:any) => {
    return {
        showModal: (tab:string) => dispatch(action.showModal(tab)),
        hideModal: () => dispatch(action.hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(navModules);
