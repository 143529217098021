import React from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { NewsRenderer, NewsType } from '../../../helper/News';

const BreakingNews = (props: any) => {
    const { t } = useTranslation();

    return (
        <section className='extranet-alerts'>
            <header>
                <SzTypographie variant='h2' weight='bold'>{t('NEWS_title')}</SzTypographie>
            </header>
            {NewsRenderer.renderNewsElement(props.news, NewsType.NEWS, props.loading, props.error)}
        </section>
    )
}

export default BreakingNews;
