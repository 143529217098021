import React, {useEffect, useState} from 'react';
import {SzBox, SzIcon, SzTypographie} from '@suezenv/react-theme-components';
import {config} from "../../../config";
import {useTranslation} from "react-i18next";
import {UrbaReportingResponse} from "../../../types/urba.type";
import "./urba.scss";
import {UrbaRepository} from "../../../services/UrbaRepository";

const Urba: React.FC = () => {
    const {t} = useTranslation();

    const [reports, setReports] = useState<UrbaReportingResponse>({
        id: '',
        projects: '-',
        potentialCount: '-',
    });

    useEffect(() => {
        UrbaRepository.getReporting().then(({data}) => setReports(data));
    }, []);

    const renderReporting = () => {
        return <>
            <div key="potential" className="metric">
                    <span className="metric-value">{
                        reports['potentialCount']
                    }</span>
                <strong className="metric-name">{t('urba_potential')}</strong>
            </div>
            <div key="projects" className="metric">
                    <span className="metric-value">{
                        reports['projects']
                    }</span>
                <strong className="metric-name">{t('urba_projects')}</strong>
            </div>
        </>
        ;
    }

    return <div className="urba-wrapper">
        <SzBox className="box--no-padding card h-100" tag="div">
            <header className="m-5">
                <SzTypographie variant="h2" weight="bold" className="d-flex align-items-center justify-content-between mb-5">
                    {t('urba_title')}
                    <a href={config.urbaModuleUrl} target="_blank" rel="noopener noreferrer">
                        <SzIcon variant="bold" icon="arrow-right"/>
                    </a>
                </SzTypographie>
            </header>
            <article className="d-flex mb-2">
                {renderReporting() ?? <div className="col">{t('urba_error')}</div>}
            </article>
        </SzBox>
    </div>
}

export default Urba;