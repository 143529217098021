export enum ReportStatusId {
    TO_APPROVE = 'to_approve',
    LATE = 'late',
    DRAFT = 'draft',
    VALIDATED = 'validated',
    TO_VALIDATE = 'to_validate',
    REFUSED = 'refused',
}

export interface ReportingStatus {
    id: ReportStatusId;
    count: number;
}

export interface IndicatorReporting {
    statusReport: {
        requestType: 'INSTANCE_INDICATEUR';
        statuses: ReportingStatus[],
    }[],
}

export type IndicatorReportingResponse = [] | IndicatorReporting[];
