import React, { useEffect, useState } from 'react';
import { SzBox, SzButton, SzTypographie, SzSpinner, SzAlert } from '@suezenv/react-theme-components';
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ApiUrls, Modes, Modules, Notifications } from '../../../constants';
import { CommonHelper } from '../../../helper/Common';
import { SubscriptionsService } from "../../../services/SubscriptionsService";
import AlertModule from './alertModule';
import Request from '../../../services/Request';

const AlertsTab = (props: any) => {
    const { t } = useTranslation();
    const { roles } = props.user;
    const [touched, setTouched] = useState(false);
    const [infoBanner, setInfoBanner] = useState(null);

    const spinner = <div className='text-center p-2'><SzSpinner variant='secondary'/></div>

    const [subscriptions, setSubscriptions] = useState(spinner)
    const [loading, setLoading] = useState(true)

    const handleToggleCheckbox = (module: string, mode: string, currentState: boolean) => {
        // bypass useless warning so we can access object as array like anywhere else.
        let subscriptionsCopy: any = subscriptions

        subscriptionsCopy = {
            ...subscriptionsCopy,
            [module]: {
                ...subscriptionsCopy[module],
                [mode]: !currentState
            }
        }

        setSubscriptions(subscriptionsCopy)
        setTouched(true)
    }

    useEffect(() => {
        SubscriptionsService.getSubscriptionsGrouped()
            .then((subscriptionsGrouped) => {
                setSubscriptions(subscriptionsGrouped)
                setLoading(false)
            });
    }, [])

    const isChecked = (subscriptions: any, module: string, mode: Modes) => {
        return (undefined !== subscriptions[module] && subscriptions[module][mode]);
    }

    const saveSubscriptionHandler = (subscriptions: any) => {
        let updatedSubscriptions = []

        for (let [moduleId, moduleCnf] of Object.entries(subscriptions)) {
            const moduleConf: any = moduleCnf
            for (let [modeId, modeValue] of Object.entries(moduleConf)) {
                const moduleModeSubcription = {
                    module: {
                        id: moduleId
                    },
                    mode: {
                        id: modeId
                    },
                    enabled: modeValue
                }

                updatedSubscriptions.push(moduleModeSubcription)
            }
        }

        Request.post(ApiUrls.POST_SUBSCRIPTIONS, updatedSubscriptions, false, true)
            .then(res => {
                // API just returns OK, response isn't used.
                setTouched(false)

                const info: any = <SzAlert transparent={false} variant="success">
                    <div>{t('subscriptions_updated')}</div>
                </SzAlert>

                setInfoBanner(info)
            })
            .catch(err => {
                const info: any = <SzAlert transparent={false} variant="danger">
                    <div>{t('subscriptions_updated_failed')}</div>
                </SzAlert>

                setInfoBanner(info)
            })
    }

    const subscriptionsRendering = roles.map((role: string, key: any) => {
        const module = CommonHelper.getModuleFromUserRole(role);
        const moduleTitle = CommonHelper.getEnumKeyByValue(Modules, module);
        const idPortal = module + Modes.portal + key;
        const idEmail = module + Modes.email + key;

        const portalChecked = isChecked(subscriptions, module, Modes.portal)
        const emailChecked = isChecked(subscriptions, module, Modes.email)

        return (
            moduleTitle && (moduleTitle in Notifications) &&
            <div className="row my-4" key={module}>
                <AlertModule
                    module={module}
                    moduleTitle={moduleTitle}
                    idPortal={idPortal}
                    idEmail={idEmail}
                    portalChecked={portalChecked}
                    emailChecked={emailChecked}
                    handleToggleCheckbox={handleToggleCheckbox}
                />
            </div>
        );
    });

    let subcriptionsView = <div className='text-center p-2'><SzSpinner variant='secondary'/></div>

    if (!loading) {
        subcriptionsView = subscriptionsRendering
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <SzTypographie weight="regular">{t('ALERTS_description')}</SzTypographie>
                        {infoBanner}
                        <SzBox className="container border">
                            <header className="row bg-dark py-4">
                                <div className="col pl-5">
                                    <SzTypographie weight="bold" className="mb-0">
                                        {t('ALERTS_title_modules')}
                                    </SzTypographie>
                                </div>
                                <div className="col-3 col-lg-2">
                                    <SzTypographie weight="bold" className="mb-0">
                                        {t('ALERTS_title_mode_portal')}
                                    </SzTypographie>
                                </div>
                                <div className="col-3 col-lg-2">
                                    <SzTypographie weight="bold" className="mb-0">
                                        {t('ALERTS_title_mode_email')}
                                    </SzTypographie>
                                </div>
                            </header>
                            {subcriptionsView}
                        </SzBox>
                    </div>
                </div>
            </div>
            <SzButton
                className="float-right mt-5"
                isDisabled={!touched}
                onClick={() => saveSubscriptionHandler(subscriptions)}
            >{t('ALERTS_save')}</SzButton>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user.info
});

export default connect(mapStateToProps, null)(AlertsTab);
