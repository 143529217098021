import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import './notifications.scss';
import {
    SzAccordion, SzAlert,
    SzBox,
    SzIcon,
    SzPagination,
    SzRadioButton,
    SzSpinner,
    SzTypographie
} from '@suezenv/react-theme-components';
import moment from "moment";
import Collapse from "../elements/collapse";
import { ApiUrls, Constants, ModalTabs, Notifications, Pagination } from "../../constants";
import Request from "../../services/Request";
import { CommonHelper } from "../../helper/Common";
import * as action from "../../actions";
import { connect } from "react-redux";
import { capitalizeFirstLetter } from "../../tools";

const NotificationsComponent = (props: any) => {
    const { t } = useTranslation();
    const itemsPerPage: number = +Constants.NOTIFICATIONS_PER_PAGE;
    const pageRangeDisplayed: number = +Constants.NOTIFICATIONS_PAGE_RANGE;
    const [notifications, setNotifications] = useState([]);
    const [pagination, setPagination] = useState({
        'x-pagination-total-count': 0,
        'x-pagination-page-count': 0,
        'x-pagination-current-page': 1,
        'x-pagination-per-page': Constants.NOTIFICATIONS_PER_PAGE
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [countLabel, setCountLabel] = useState('');
    const options = [
        {
            label: t('NOTIFICATIONS_unread'),
            value: 'unread'
        },
        {
            label: t('NOTIFICATIONS_archived'),
            value: 'archived'
        }
    ];
    const [showNotification, setShowNotification] = useState('unread');
    const handleShowNotification = (value: React.SetStateAction<string>) => {
        setShowNotification(value);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (pageNumber: any) => {
        setCurrentPage(pageNumber);
        setLoading(true);
    };


    const getNotifications = (currentPage: number = 1, itemsPerPage: number = 20, archived: boolean = false) => {
        return Request.getWithParams(ApiUrls.GET_NOTIFICATIONS, {
            'currentPage': currentPage,
            'itemsPerPage': itemsPerPage,
            'archived': archived
        }, false, true);
    }

    const archiveNotifications = (idNotification: any) => {
        const endpoint = ApiUrls.DELETE_SUBSCRIPTIONS.replace('{id}', idNotification);
        return Request.delete(endpoint, {}, false, true)
    }

    const loadNotifications = () => {
        const archived = 'archived' === showNotification;
        getNotifications(currentPage, itemsPerPage, archived)
            .then(response => {
                setPagination(CommonHelper.getPaginationInfo(response.headers));
                setNotifications(response.data);

            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const clickToAlerts = () => {
        props.showModal(ModalTabs.ALERTS)
    }

    const archiveHandler = (idNotification: number) => {
        archiveNotifications(idNotification)
            .then(() => {
                loadNotifications();
            });
    }

    useEffect(() => {
        loadNotifications();
    }, [currentPage, showNotification])


    useEffect(() => {
        const key = ('NOTIFICATIONS_%s_count').replace('%s', showNotification);
        setCountLabel(t(key, { count: +pagination[Pagination.PAGINATION_TOTAL_COUNT] }))
    }, [pagination]);

    const printDescription = (notification: any) => {
        const { appSource, createdAt, vars } = notification;
        const { incrementalId } = vars || {};
        const description = [];

        if (incrementalId && Notifications.requests === appSource) {
            description.push(t('NOTIFICATIONS_request_number').replace('%s', incrementalId));
        }
        if (Notifications.indicators === appSource) {
            description.push(
                t('NOTIFICATIONS_indicator_campaign').replace(
                    '%s',
                    capitalizeFirstLetter(vars.campaign)
                )
            );
            description.push(
                t('NOTIFICATIONS_indicator_code').replace(
                    '%s',
                    vars.indicatorCode
                )
            );
        }

        const date = moment(createdAt).format('DD/MM/YYYY');
        description.push(t('NOTIFICATIONS_created_at').replace('%s', date));

        return description.join(' | ');
    }

    const linkHandler = (idNotification: number, link: string) => {
        archiveNotifications(idNotification)
            .then(() => {
                loadNotifications();
                window.open(link, '_blank');
            });
    }

    const renderNotifications = () => {
        if (loading) {
            return <div className='text-center'><SzSpinner variant='secondary' /></div>
        }

        if (error) {
            return (
                <SzAlert transparent={true} variant='danger'>
                    <SzTypographie variant='body' weight='bold' className="p-4 m-0">
                        {t('NOTIFICATIONS_error')}
                    </SzTypographie>
                </SzAlert>
            )
        }

        return (
            <>
                <div className="scrollContainer overflow-auto p-1">
                    <SzAccordion>
                        {notifications.map((notification: any, key: any) => {
                            const { appSource, id, link, subject } = notification;
                            const type = CommonHelper.getEnumKeyByValue(Notifications, appSource);
                            const description = printDescription(notification);
                            return (
                                <Collapse
                                    key={key}
                                    archiveHandler={() => archiveHandler(id)}
                                    description={description}
                                    linkHandler={() => linkHandler(id, link)}
                                    title={subject}
                                    type={type}
                                    hideArchiveAction={'archived' !== showNotification}
                                />
                            )
                        })}
                    </SzAccordion>
                </div>
                <div className="row">
                    <div className="col mt-4 d-flex justify-content-center">
                        <SzPagination
                            activePage={currentPage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={+pagination[Pagination.PAGINATION_TOTAL_COUNT]}
                            pageRangeDisplayed={pageRangeDisplayed}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <section className='notifications'>
            <SzBox className="p-5 border">
                <header className="mb-3 row">
                    <div className="col">
                        <SzTypographie variant="h2" weight="bold">{t('notifications')}</SzTypographie>
                        <SzTypographie variant="caption" weight="medium" className="caption">
                            {countLabel}
                        </SzTypographie>
                    </div>
                    <div className="col-4">
                        <SzRadioButton
                            value={showNotification}
                            onChange={handleShowNotification}
                            name={'noIconOpt'}
                            choices={options}
                        />
                    </div>
                    <div className="col-1 pb-3 d-flex align-items-center">
                        <SzIcon variant="line" icon="cog" className="icon-big cursor-pointer" onClick={clickToAlerts} />
                    </div>
                </header>
                {renderNotifications()}
            </SzBox>
        </section>

    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showModal: (tab: string) => dispatch(action.showModal(tab))
    }
}

export default connect(null, mapDispatchToProps)(NotificationsComponent);
