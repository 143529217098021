import React from 'react';
import { SzTypographie, SzBox, SzIcon } from '@suezenv/react-theme-components';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const ProfileTab = (props:any) => {
    const { t } = useTranslation();
    const {firstName, lastName, organisation, phone, mobilePhone, email} = props.user

    return (
        <>
            <SzTypographie variant='h2' weight='bold' className='mb-4'>{t('USER_INFO_your_identity')}</SzTypographie>
            <SzBox tag='div' className='row border p-5 mb-5'>
                <div className='col-12 col-lg-6'>
                    <div className='row pb-4'>
                        <div className='col-3 text-center align-self-center'>
                            <SzIcon variant="line" icon="single-neutral-id-card-3" className="icon-big"/>
                        </div>
                        <div className='col-9'>
                            <SzTypographie variant="caption" className="caption">{t('USER_INFO_identity')}</SzTypographie>
                            <SzTypographie variant='h2' weight='bold'>{firstName} {lastName}</SzTypographie>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    {organisation && <div className='row pb-4 section-border-left'>
                        <div className='col-3 text-center align-self-center'>
                            <SzIcon variant="line" icon="network-users" className="icon-big"/>
                        </div>
                        <div className='col-9'>
                            <SzTypographie variant="caption"
                                           className="caption mb-3">{t('USER_INFO_organisation')}</SzTypographie>
                            <SzTypographie variant='h2' weight='bold'>{organisation}</SzTypographie>
                        </div>
                    </div>}
                </div>
            </SzBox>
            <SzTypographie variant='h2' weight='bold' className='mb-4'>{t('USER_INFO_your_contact')}</SzTypographie>
            <SzBox tag='div' className='row border p-5 mb-5'>
                {(phone || mobilePhone) && <div className='col-12 col-lg-6 section-border-right'>
                    {phone && <div className='row pb-4'>
                        <div className='col-3 text-center align-self-center'>
                            <SzIcon variant="line" icon="phone-actions-voice-mail" className="icon-big"/>
                        </div>
                        <div className='col-9'>
                            <SzTypographie variant="caption"
                                           className="caption mb-3">{t('USER_INFO_phone')}</SzTypographie>
                            <SzTypographie variant='h2' weight='bold'>{phone.replace(/(\d{2})/g, '$1 ')}</SzTypographie>
                        </div>
                    </div>}
                    {mobilePhone && <div className='row pb-4'>
                        <div className='col-3 text-center align-self-center'>
                            <SzIcon variant="line" icon="mobile-phone" className="icon-big"/>
                        </div>
                        <div className='col-9'>
                            <SzTypographie variant="caption"
                                           className="caption mb-3">{t('USER_INFO_gsm')}</SzTypographie>
                            <SzTypographie variant='h2'
                                           weight='bold'>{mobilePhone.replace(/(\d{2})/g, '$1 ')}</SzTypographie>
                        </div>
                    </div>}
                </div>}
                <div className='col-12 col-lg-6'>
                    <div className='row pb-4'>
                        <div className='col-3 text-center align-self-center'>
                            <SzIcon variant="line" icon="envelope" className="icon-big"/>
                        </div>
                        <div className='col-9'>
                            <SzTypographie variant="caption" className="caption mb-3">{t('USER_INFO_email')}</SzTypographie>
                            <SzTypographie variant='h2' weight='bold'>{email}</SzTypographie>
                        </div>
                    </div>
                </div>
            </SzBox>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.user.info
});

export default connect(mapStateToProps, null)(ProfileTab);